// stores/alertStore.js
import { defineStore } from 'pinia'

export const useAlertStore = defineStore('alertStore', {
  state: () => ({
    alerts: [],
  }),
  actions: {
    addAlert(alert) {
      const id = Date.now() // Simple unique ID for demonstration
      this.alerts.push({ ...alert, id, visible: true })
      if (alert.duration) {
        setTimeout(() => {
          this.removeAlert(id)
        }, alert.duration * 1000)
      }
    },
    removeAlert(id) {
      const index = this.alerts.findIndex((a) => a.id === id)
      if (index !== -1) {
        this.alerts[index].visible = false // Optionally animate out
        setTimeout(() => {
          this.alerts.splice(index, 1)
        }, 500) // Allow for exit animation
      }
    },
  },
})
